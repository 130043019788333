export const columns = [
	{
		prop: 'carNumber',
		label: '车牌号',
		width: '120px',
	},
	{
		prop: 'status',
		label: '扣分状态',
		dict: 'car_violate_status',
		width: '120px',
	},
	{
		prop: 'paymentStatus',
		label: '交款状态',
		dict: 'car_violate_payment_status',
		width: '120px',
	},
	{
		prop: 'violateDate',
		label: '违章日期',
		width: '180px',
	},
	{
		prop: 'violateScore',
		label: '违章扣分',
		width: '100px',
	},
	{
		prop: 'violateFine',
		label: '违章罚款',
		width: '100px',
	},
	{
		prop: 'carModel',
		label: '车型名称',
		width: '250px',
	},
	{
		prop: 'carNum',
		label: '自编号',
		width: '120px',
	},

	{
		prop: 'carColor',
		label: '颜色',
		width: '140px',
	},
	{
		prop: 'driverName',
		label: '司机姓名',
		width: '120px',
	},
	{
		prop: 'driverSupportName',
		label: '司服伙伴',
		width: '120px',
	},

	{
		prop: 'violateDays',
		label: '已发生天数',
		width: '100px',
	},
	{
		prop: 'violateCity',
		label: '违章所在城市',
		width: '140px',
	},
	{
		prop: 'violateBehavior',
		label: '违法行为',
		width: '320px',
	},
	{
		prop: 'penaltyDecisionNo',
		label: '决定书编号',
		width: '220px',
	},
	{
		prop: 'violateLocation',
		label: '违章地点',
		width: '320px',
	},
	{
		prop: 'violatePhotos',
		label: '违章图片',
		width: '140px',
	},
	{
		prop: 'companyName',
		label: '所属公司',
		width: '320px',
	},
	{
		prop: 'assetCompanyName',
		label: '资产所属公司',
		width: '320px',
	},
	{
		prop: 'useNature',
		label: '使用性质',
		width: '140px',
	},
	{
		prop: 'createTime',
		label: '创建时间',
	},
	{
		prop: 'updateTime',
		label: '更新时间',
	},
]
export const setting = {
	expand: true,
	filters: [
		{
			attr: 'keyword',
			label: '车辆信息',
			type: 'search',
			placeholder: '输入车型名称、车牌、自编号、司机姓名、手机号搜索',
			clearable: true,
			width: 358,
		},
		{
			attr: 'violateMonth',
			label: '违章月份',
			type: 'month',
		},
		{
			attr: 'status',
			label: '扣分状态',
			type: 'select',
			placeholder: '请选择扣分状态',
			options: [],
		},
		{
			attr: 'driverSupportId',
			label: '司服伙伴',
			type: 'select',
			placeholder: '请选择司服伙伴',
			options: [],
		},
		{
			attr: 'paymentStatus',
			label: '交款状态',
			type: 'select',
			placeholder: '请选择交款状态',
			options: [],
		},
		{
			attr: 'companyId',
			label: '所属公司',
			type: 'multi-select',
			placeholder: '请选择所属公司',
			width: 320,
			options: [],
		},
		{
			attr: 'assetCompanyId',
			label: '资产所属公司',
			type: 'multi-select',
			placeholder: '请选择资产所属公司',
			width: 320,
			options: [],
		},
		{
			attr: 'violateDate',
			label: '违章日期',
			type: 'date',
		},
	],
}
